<template>
  <div
    class="header-container"
    :class="{ 'header-container--with-logo': !!organizationLogo }">
    <div class="header-container__before">
      <template v-if="!!icon">
        <!-- svg-inline resolve before computed -->
        <svg width="223" height="181" viewBox="0 0 223 181" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="icon == 'heart'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M192.82 5.286a58.422 58.422 0 00-82.767 0l-8.33 8.44-8.331-8.44a58.424 58.424 0 00-82.767 0c-22.937 22.937-24.343 61.67 4.544 91.098l86.554 86.553 86.553-86.553c28.887-29.428 27.481-68.161 4.544-91.098z" stroke="var(--v-secondaryColor-base)" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <svg width="202" height="180" viewBox="0 0 202 180" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'history'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.996-13.08v50.014m0 0H60.01m-50.014 0L40.004 9.927a90.024 90.024 0 11-29.508 77.02m89.524-60.015v50.013l40.011 20.005" stroke="var(--v-secondaryColor-base)" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="287" height="181" fill="none" viewBox="0 0 287 175" v-else-if="icon == 'learn'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path stroke="var(--v-secondaryColor-base)" stroke-linecap="round" stroke-linejoin="round" stroke-width="19.792" d="M51.166 104.084C39.782 91.74 32.833 75.279 32.833 57.202c0-38.298 31.19-69.344 69.667-69.344 38.476 0 69.666 31.046 69.666 69.344 0 14.637-2.839 26.719-12.333 39.405s-20.667 22.972-20.667 64.114H58.5m80.666 41.612H58.5m41.333-316.666v42.8m133 129.067h43.333M195.5-36.056l30.641-30.5M9.474-36.056l-30.641-30.5m-51 124.09h43.333"/></svg>
        <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'meditate'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.034 36.149V23.146c0-2.169 1.718-3.927 3.837-3.927h9.184c2.119 0 3.837 1.758 3.837 3.927v13.003m-16.858 0l-3.907-2.952c-3.351-2.533-8.12-.335-8.127 3.928 0 .51.005 1.004.02 1.47.022.697.068 1.5.123 2.298A4.394 4.394 0 006.52 45h12.074m-4.56-8.851l8.407 6.082m8.45-6.082l-8.45 6.082m8.45-6.082l3.888-2.954c3.339-2.537 8.111-.368 8.207 3.887.009.412.014.811.014 1.191 0 .857-.067 1.872-.157 2.849C42.636 43.362 40.75 45 38.55 45H26.268m-3.827-2.769L18.594 45m3.847-2.769L26.268 45m-7.674 0h7.674m-3.837-19.408v4.249m6.605-21.082c0 3.733-2.957 6.759-6.605 6.759-3.647 0-6.604-3.026-6.604-6.759C15.827 5.026 18.784 2 22.43 2c3.648 0 6.605 3.026 6.605 6.759z" stroke="var(--v-secondaryColor-base)" stroke-width="2.5" stroke-linecap="round"/></svg>
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'sleep'" v-bind:svg-inline="''" v-bind:class="'header-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_1759_2148)"><mask id="a" fill="#fff"><path fill-rule="evenodd" clip-rule="evenodd" d="M43 26.5c0-.903-1.192-1.205-1.792-.531A14.963 14.963 0 0130 30.999c-8.284 0-15-6.715-15-15 0-3.28 1.053-6.315 2.84-8.784.555-.767-.016-1.924-.94-1.722C7.236 7.599 0 16.204 0 26.5 0 38.374 9.626 48 21.5 48S43 38.374 43 26.5z"/></mask><path d="M17.84 7.215l-2.025-1.466 2.025 1.466zm21.5 17.092A12.463 12.463 0 0130 28.5v5c5.2 0 9.874-2.272 13.075-5.869l-3.734-3.324zM30 28.5c-6.904 0-12.5-5.597-12.5-12.5h-5c0 9.665 7.835 17.5 17.5 17.5v-5zM17.5 16c0-2.738.877-5.263 2.366-7.32l-4.05-2.931a17.432 17.432 0 00-3.316 10.25h5zm-15 10.5c0-9.095 6.393-16.703 14.932-18.564L16.367 3.05C5.579 5.402-2.5 15.004-2.5 26.5h5zm19 19c-10.493 0-19-8.507-19-19h-5c0 13.254 10.745 24 24 24v-5zm19-19c0 10.493-8.507 19-19 19v5c13.255 0 24-10.746 24-24h-5zM19.866 8.68a3.668 3.668 0 00.272-3.852c-.615-1.17-2.018-2.16-3.771-1.778l1.065 4.886c-.34.074-.732.03-1.082-.155a1.51 1.51 0 01-.639-.627 1.332 1.332 0 01.104-1.405l4.05 2.931zm23.21 18.951a1.395 1.395 0 01-1.42.42 1.554 1.554 0 01-.769-.505A1.65 1.65 0 0140.5 26.5h5c0-1.767-1.243-2.914-2.499-3.265a3.605 3.605 0 00-3.66 1.072l3.734 3.324z" fill="var(--v-secondaryColor-base)" mask="url(#a)"/><path d="M27 1.5h7L27 12h7M40 11h6l-6 8h6" stroke="var(--v-secondaryColor-base)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1759_2148"><path fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs></svg>
      </template>
    </div>
    <img
      class="header-logo"
      v-if="!!organizationLogo"
      :src="organizationLogo" />
    <div class="header-title text-uppercase">{{ title }}</div>
  </div>
</template>

<script>
  import CurvedHeaderMixin from '@/components/general/curved-header/CurvedHeader.mixin.vue';
  export default {
    mixins: [CurvedHeaderMixin],
  };
</script>

<style lang="scss" scoped>
  .header-container {
    position: relative;
    overflow: hidden !important;
    height: 22vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    $this: &;
    &__before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 200%;
      width: 200%;
      background-image: linear-gradient(90deg, var(--v-primaryColor-base) 31.9%, var(--v-secondaryColor-base) 67.96%);
      border-radius: 50%;
      overflow: hidden;
    }
    &--with-logo {
      color: var(--v-offBlack-base);
      height: 32vh;
      #{$this}__before {
        background-image: none;
        background-color: var(--v-offWhite-base);

        opacity: 0.7;
      }
    }
  }
  .header-curve {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 10vh;
    width: 100vw;
    height: 22vh;
  }

  .header-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.3rem;
    z-index: 2;
    width: 80%;
  }
  .header-logo {
    width: 100%;
    max-width: 156px;
    max-height: 25%;
    object-fit: contain;
    z-index: 1;
    margin-bottom: 28px;
  }
  .header-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(25% - 10px);
    max-width: 180px;
    height: calc(50% + 10px);
  }
</style>
