<script>
  export default {
    name: 'CurvedHeader',
    props: {
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
      },
      icon: {
        type: String,
        required: false,
        default: 'basic',
      },
      organizationLogo: {
        type: String,
      },
    },
  };
</script>
